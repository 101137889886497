exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-campaign-index-js": () => import("./../../../src/pages/campaign/index.js" /* webpackChunkName: "component---src-pages-campaign-index-js" */),
  "component---src-pages-campaign-looker-studio-js": () => import("./../../../src/pages/campaign/looker-studio.js" /* webpackChunkName: "component---src-pages-campaign-looker-studio-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-pages-js": () => import("./../../../src/pages/landing-pages.js" /* webpackChunkName: "component---src-pages-landing-pages-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-templates-projects-page-js": () => import("./../../../src/templates/ProjectsPage.js" /* webpackChunkName: "component---src-templates-projects-page-js" */)
}

